import AheadAiProject from "../components/projects/aheadai";
import Layout from "../layouts";
import OcoProject from "../components/projects/oco";
import React from "react";
import ReleasePageProject from "../components/projects/releasepage";
import TemptProject from "../components/projects/tempt";

const WorkPage = () => {
  return (
    <Layout>
      <p className="leads-the-things emboldernised">
        Check out our case studies for an in-depth look at some of our past
        work.
      </p>
      <div className="projects">
        <TemptProject />
        <AheadAiProject />
        <OcoProject />
        <ReleasePageProject />
      </div>

      <div className="open-source">
        <h2 className="leads-the-things">Open Source</h2>
        <p>
          Like many development companies, Squarecat couldn't exist without open
          source libraries. We strongly believe that free and open software is
          the future, and as such we publish as many of our apps as possible to
          our public GitHub.
        </p>

        <ul className="oss-projects">
          <li>
            <a href="https://github.com/leavemealone-app/email-trackers">
              Email Tracker Block List
            </a>{" "}
            - A list of email pixel trackers for use with Adblock or uBlock.
          </li>
          <li>
            <a href="https://github.com/leavemealone-app/ethical-email-manifesto">
              Ethical Email Manifesto
            </a>{" "}
            - A code of ethics for responsible email newsletter senders
          </li>
          <li>
            <a href="https://github.com/releasepage/version.js">Version.js</a> -
            Display the latest version number of your GitHub repo, shiny!
          </li>
          <li>
            <a href="https://github.com/releasepage/reload.js">Reload.js</a> -
            Show a popup or refresh your app when there is a new GitHub version
            released.
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default WorkPage;
