import Carousel from "nuka-carousel";
import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import testimonialImg from "../../assets/images/testimonials/sherveen-mashayekhi.png";

export default function OcoProject() {
  return (
    <div className="project">
      <div className="project__details">
        <h3 className="leads-the-things">Ahead.ai</h3>
        <Carousel
          className="project__carousel"
          cellAlign="center"
          framePadding="0px"
        >
          <StaticImage src={`../../assets/images/work/aheadai/1.png`} />
          <StaticImage src={`../../assets/images/work/aheadai/2.png`} />
          <StaticImage src={`../../assets/images/work/aheadai/3.png`} />
          <StaticImage src={`../../assets/images/work/aheadai/4.png`} />
          <StaticImage src={`../../assets/images/work/aheadai/5.png`} />
          <StaticImage src={`../../assets/images/work/aheadai/6.png`} />
        </Carousel>
        <p className="project__description">
          Stella.ai are building the world's first shared talent network. They
          enlisted us to bring their new career tracking product to life. We
          architected and built a bespoke skills management and job tracking
          system, working closely with their Head of Product to perfect the
          onboarding process, and make it easy for people to track progress in
          advancing their skillsets and qualifying for new jobs.
        </p>
        <Link to="/work/aheadai">Read more...</Link>

        <blockquote className="testimonial">
          <div className="testimonial__content">
            <img
              className="testimonial__author-image"
              src={testimonialImg}
              alt=""
              role="presentation"
            />
            <p className="testimonial__text">
              "It was a pleasure to work with Squarecat, I would love to work
              with them again on future projects!"
            </p>
          </div>
          <cite className="testimonial__author">
            Sherveen Mashayekhi, Ahead.ai
          </cite>
        </blockquote>
      </div>
    </div>
  );
}
