import Carousel from "nuka-carousel";
import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function OcoProject() {
  return (
    <div className="project">
      <div className="project__details">
        <h3 className="leads-the-things">
          ReleasePage{" "}
          <span className="muted">Better release communication</span>
        </h3>
        <Carousel
          className="project__carousel"
          cellAlign="center"
          framePadding="0px"
        >
          <StaticImage src={`../../assets/images/work/releasepage/1.png`} />
          <StaticImage src={`../../assets/images/work/releasepage/2.png`} />
          <StaticImage src={`../../assets/images/work/releasepage/3.png`} />
          <StaticImage src={`../../assets/images/work/releasepage/4.png`} />
          <StaticImage src={`../../assets/images/work/releasepage/5.png`} />
          <StaticImage src={`../../assets/images/work/releasepage/6.png`} />
          <StaticImage src={`../../assets/images/work/releasepage/7.png`} />
          <StaticImage src={`../../assets/images/work/releasepage/8.png`} />
        </Carousel>
        <p className="project__description">
          ReleasePage is one of our passion projects which provides an easier
          way for projects to communicate new software releases with their
          users. We architected, designed, and built a platform a for users to
          create branded, totally customised, beautiful web pages for their
          software release notes.
        </p>
        <Link to="/work/releasepage">Read more...</Link>
      </div>
    </div>
  );
}
