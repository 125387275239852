import Carousel from "nuka-carousel";
import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import testimonialImg from "../../assets/images/testimonials/david-whiteman.png";

export default function OcoProject() {
  return (
    <div className="project">
      <div className="project__details">
        <h3 className="leads-the-things">Tempt</h3>
        <Carousel
          className="project__carousel"
          cellAlign="center"
          framePadding="0px"
        >
          <StaticImage src={`../../assets/images/work/tempt/1.png`} />
          <StaticImage src={`../../assets/images/work/tempt/2.png`} />
          <StaticImage src={`../../assets/images/work/tempt/3.png`} />
        </Carousel>
        <p className="project__description">
          Tempt hired us to build a new product in the e-commerce space for
          brands to dynamically create personal offers. We suggested starting
          with Shopify and designed, architected, and built an elegant system
          that intelligently optimized the offers and gave stores more customer
          engagement, higher margins, and better brand control.
        </p>
        <Link to="/work/tempt">Read more...</Link>

        <blockquote className="testimonial">
          <div className="testimonial__content">
            <img
              className="testimonial__author-image"
              src={testimonialImg}
              alt=""
              role="presentation"
            />
            <p className="testimonial__text">
              “James and Danielle are a fantastic team. They are skilled,
              diligent, smart and creative and all-round lovely people to work
              with. I’d hire Squarecat again in a heartbeat.”
            </p>
          </div>
          <cite className="testimonial__author">David Whiteman, Tempt</cite>
        </blockquote>
      </div>
    </div>
  );
}
