import Carousel from "nuka-carousel";
import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import testimonialImg from "../../assets/images/testimonials/stu-dixon.png";

export default function OcoProject() {
  return (
    <div className="project">
      <div className="project__details">
        <h3 className="leads-the-things">
          OCO <span className="muted">Offices & Coworking</span>
        </h3>

        <Carousel
          className="project__carousel"
          cellAlign="center"
          framePadding="0px"
        >
          <StaticImage src={`../../assets/images/work/oco/1.png`} />
          <StaticImage src={`../../assets/images/work/oco/2.png`} />
          <StaticImage src={`../../assets/images/work/oco/3.png`} />
          <StaticImage src={`../../assets/images/work/oco/4.png`} />
          <StaticImage src={`../../assets/images/work/oco/5.png`} />
          <StaticImage src={`../../assets/images/work/oco/6.png`} />
          <StaticImage src={`../../assets/images/work/oco/7.png`} />
        </Carousel>
        <p className="project__description">
          OCO approached us for advice rebuilding and rebranding their coworking
          product from the ground up. We designed and built a new marketing
          website and bespoke office management system, working with their
          consultants to make the process of onboarding and lead discovery
          simpler and less time intensive.
        </p>
        <Link to="/work/oco">Read more...</Link>

        <blockquote className="testimonial">
          <div className="testimonial__content">
            <img
              className="testimonial__author-image"
              src={testimonialImg}
              alt=""
              role="presentation"
            />
            <p className="testimonial__text">
              "Squarecat did an outstanding job throughout the time we worked
              together. They represent a first class combination of technical
              expertise, diligence, and attention to detail. They were a vital
              part of the team and we couldn’t have delivered such a great
              product to the client without them."
            </p>
          </div>
          <cite className="testimonial__author">
            Stu Dixon, <a href="http://splendidwebs.com/">Splendid Webs</a>
          </cite>
        </blockquote>
      </div>
    </div>
  );
}
